/* lists
   ========================================================================== */

// Modal header
// Top section of the modal w/ title and dismiss
.modal-header {
  padding: $modal-header-padding-y $modal-header-padding-x 0 $modal-header-padding-x;

  .close {
    padding: $modal-header-padding-y $modal-header-padding-x 0 $modal-header-padding-x;
    // auto on the left force icon to the right even when there is no .modal-title
    margin: (-$modal-header-padding-y) (-$modal-header-padding-x) 0 auto;
  }
}

// Modal body
// Where all modal content resides (sibling of .modal-header and .modal-footer)
.modal-body {
  // Enable `flex-grow: 1` so that the body take up as much space as possible
  // when should there be a fixed height on `.modal-dialog`.
  padding: $modal-inner-padding-y $modal-inner-padding-x;

  &:not(:first-child) {
    padding-top: rem(8px);
  }

  &:not(:last-child) {
    padding-bottom: 0;
  }

  p:last-child {
    margin-bottom: 0;
  }
}

// Footer (for actions)
.modal-footer {
  justify-content: flex-start;
  padding: $modal-footer-padding;

  &:not(:first-child) {
    padding-top: rem(12px);
  }

  // Easily place margin between footer elements
  > :not(:first-child) {
    margin-left: .625rem;
  }
  > :not(:last-child) { margin-right: .625rem; }
}
