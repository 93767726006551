//
// Examples
//

.bd-examples .img-thumbnail {
  margin-bottom: .75rem;
}
.bd-examples h4 {
  margin-bottom: .25rem;
}
.bd-examples p {
  margin-bottom: 1.25rem;
}

@media (max-width: 480px) {
  .bd-examples {
    margin-right: -.75rem;
    margin-left: -.75rem;
  }
  .bd-examples > [class^="col-"] {
    padding-right: .75rem;
    padding-left: .75rem;
  }
}

.doc-example-link {
  transition: .3s;
}
.doc-example-link:hover {
  background-color: $doc-highlight-bg;
  opacity: .6;
}
