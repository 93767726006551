/* dropdown
   ========================================================================== */

.dropdown-mastheader {
  .dropdown-menu {
    padding: rem(30px);
    background-color: gray("600");
    border-color: gray("600");

    &::before,
    &::after {
      right: rem(62px);
    }
  }

  &.dropdown .dropdown-menu[x-placement="bottom-start"],
  &.dropdown .dropdown-menu[x-placement="bottom-end"],
  &.dropup .dropdown-menu[x-placement="bottom-start"],
  &.dropup .dropdown-menu[x-placement="bottom-end"] {
    &::after {
      border-bottom-color: gray("600");
    }

    &::before {
      border-bottom-color: gray("600");
    }
  }

  &.dropdown .dropdown-menu[x-placement="top-start"],
  &.dropdown .dropdown-menu[x-placement="top-end"],
  &.dropup .dropdown-menu[x-placement="top-start"],
  &.dropup .dropdown-menu[x-placement="top-end"] {
    &::after {
      border-top-color: gray("600");
    }

    &::before {
      border-top-color: gray("600");
    }
  }

  .dropdown-close {
    position: absolute;
    top: rem(20px);
    right: rem(20px);
    color: color("white");
    cursor: pointer;
  }

  .dropdown-lang {
    .dropdown-menu {
      padding-top: rem(20px);
      padding-bottom: rem(20px);
      &::before,
      &::after {
        right: rem(46px);
      }
    }
  }

  .dropdown-menu-lang-item {
    display: flex;
    align-items: center;
    color: gray("200");
    @include hover-focus-active {
      color: color("white");
    }

    &:not(:last-child) {
      padding-bottom: rem(20px);
    }
  }
}

.dropdown-menu-lang-item {
  background-color: transparent;
  border: none;
}

.dropdown-menubar {
  .btn {
    justify-content: space-between;
    padding-left: rem(20px);
  }

  .btn,
  .dropdown-menu {
    background-color: gray("100");
  }

  .dropdown-menu {
    padding-right: rem(20px);
    padding-bottom: rem(20px);
    padding-left: rem(20px);
    border: 0;
  }
}
