/* Search input */
.algolia-autocomplete {
  display: block;
  flex: 1;
  width: 100%;

  .algolia-docsearch-suggestion,
  .ds-dropdown-menu::before {
    background-color: $algolia-bg;
  }
}

/* Main dropdown wrapper */
.algolia-autocomplete .ds-dropdown-menu {
  margin-top: 1rem;
  background-color: $algolia-bg;
  border: $algolia-border-color solid 1px;
  border-radius: $border-radius;
  box-shadow: none;

  [class^="ds-dataset-"] {
    background-color: $algolia-bg;
    border: none;
    border-radius: $border-radius;
    box-shadow: none;
  }

  /* stylelint-disable selector-max-class, selector-max-compound-selectors */
  .ds-suggestion.ds-cursor .algolia-docsearch-suggestion {
    &.suggestion-layout-simple,
    &:not(.suggestion-layout-simple) .algolia-docsearch-suggestion--content {
      background-color: $algolia-hover-bg;
    }
  }
  /* stylelint-enable selector-max-class, selector-max-compound-selectors */
}

@include media-breakpoint-down(sm) {
  .algolia-autocomplete .ds-dropdown-menu {
    min-width: 0;
  }
}

/* Main category (eg. Getting Started) */
.algolia-autocomplete .algolia-docsearch-suggestion--category-header {
  align-items: center;
  margin-bottom: rem(14px);
  font-size: $font-size-xs;
  font-weight: 500;
  color: $algolia-header-color;
  text-transform: uppercase;
  border: none;
}

/* Category (eg. Downloads) */
.algolia-autocomplete .algolia-docsearch-suggestion--subcategory-column {
  color: $algolia-column-color;
}

/* Title (eg. Bootstrap CDN) */
.algolia-autocomplete .algolia-docsearch-suggestion--title {
  font-size: 1rem;
  font-weight: 600;
  color: $algolia-title-color;
}

/* Description description (eg. Bootstrap currently works...) */
.algolia-autocomplete .algolia-docsearch-suggestion--text {
  font-size: .9rem;
  font-weight: 400;
  color: $algolia-text-color;
}

/* Highlighted text */
.algolia-autocomplete .algolia-docsearch-suggestion--highlight {
  color: $algolia-highlight-color;
}
