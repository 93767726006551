// stylelint-disable selector-max-class, selector-max-type
.bd-toc {
  border-left: 1px solid $bd-toc-border-color;
}
.bd-toc nav ul {
  padding-left: 1rem;
  list-style: none;

  &:first-child {
    padding: 0;
  }

  & a {
    display: block;
    padding: .125rem 1.5rem;
    color: $bd-toc-color;

    &:hover {
      color: $blue;
      text-decoration: none;
    }
  }
}
// stylelint-enable selector-max-class, selector-max-type
