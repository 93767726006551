@font-face {
  font-family: Avenir;
  src: url("../assets/fonts/avenir/avenir-lighter.woff");
  font-weight: 300;
}

@font-face {
  font-family: Avenir;
  src: url("../assets/fonts/avenir/avenir-book.woff");
  font-weight: 400;
}

@font-face {
  font-family: Avenir;
  src: url("../assets/fonts/avenir/avenir-medium.woff");
  font-weight: 500;
}

@font-face {
  font-family: Avenir;
  src: url("../assets/fonts/avenir/avenir-black.woff");
  font-weight: 900;
}
