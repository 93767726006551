/* imports
   ========================================================================== */

// Light/Dark mode modifications
//@import "~bootstrap/scss/functions";
//@import "common/tools/functions";
//@import "common/settings/variables";

@import "extern/settings/variables";
@import "~bootstrap/scss/vendor/rfs";
@import "~bootstrap/scss/mixins/deprecate";
@import "common/tools/mixins";
@import "common/tools/colors";

// vendors
@import "~flatpickr/dist/flatpickr";
@import "~ion-rangeslider/css/ion.rangeSlider";
@import "~swiper/dist/css/swiper";

// generic
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "common/generic/base";
@import "common/generic/fonts";
@import "common/generic/icons";
@import "extern/generic/type";

// objects
@import "extern/layout/grid";
@import "~bootstrap/scss/tables";

// layouts
@import "extern/layout/mastheader";
@import "extern/layout/actionbar";
@import "extern/layout/menubar";
@import "extern/layout/mastcontainer";
@import "extern/layout/mastfooter";

// components
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/code";
@import "~bootstrap/scss/forms";
@import "common/components/forms";
@import "common/components/buttons";
@import "extern/components/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "common/components/dropdown";
@import "extern/components/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/input-group";
@import "common/components/input-group";
@import "~bootstrap/scss/custom-forms";
@import "common/components/custom-forms";
@import "extern/components/custom-forms";
@import "common/components/select";
@import "extern/components/select";
// @import "~bootstrap/scss/nav";
// @import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/breadcrumb";
@import "common/components/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "common/components/pagination";
@import "~bootstrap/scss/badge";
@import "common/components/sami";
@import "~bootstrap/scss/jumbotron";
@import "~bootstrap/scss/alert";
@import "common/components/progress";
@import "~bootstrap/scss/media";
@import "common/components/lists";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "common/components/close";
@import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/modal";
@import "common/components/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "common/components/popover";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/spinners";
@import "common/components/management-item";
@import "common/components/chips";
@import "common/components/toolbar";
@import "common/components/navtabs";
@import "extern/components/navtabs";
@import "common/components/flatpickr";
@import "common/components/form-toolbar";
@import "common/components/range-slider";
@import "common/components/advanced-search";
@import "common/components/accordion";
@import "extern/components/proof";
@import "extern/components/editorial";
@import "extern/components/swiper";
@import "extern/components/slideshow";
@import "extern/components/carousel";
@import "extern/components/tongues";
@import "extern/components/anchor";
@import "extern/components/vignettes";

// trumps
@import "~bootstrap/scss/utilities";
@import "common/trumps/backgrounds";
@import "common/trumps/icons";
@import "common/trumps/flex";
@import "common/trumps/overflow";
@import "common/trumps/spacing";
@import "common/trumps/text";
@import "~bootstrap/scss/print";
