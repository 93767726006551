/* popover
   ========================================================================== */

.bs-popover-top {
  margin-bottom: calc(#{$popover-arrow-height} + 5px);
}

.bs-popover-right {
  margin-left: calc(#{$popover-arrow-height} + 5px);
}

.bs-popover-bottom {
  margin-top: calc(#{$popover-arrow-height} + 5px);
}

.bs-popover-left {
  margin-right: calc(#{$popover-arrow-height} + 5px);
}

// Offset the popover to account for the popover arrow
.popover-header {
  padding-bottom: 0;
  border: 0;

  &:not(:empty) + .popover-body {
    padding-top: rem(2px);
  }
}

.popover {
  width: 100%;

  &::before {
    position: absolute;
    top: rem(25px);
    right: rem(25px);
    display: block;
    width: rem(12px);
    height: rem(12px);
    cursor: pointer;
    content: "";
    background-image: $popover-close-svg;
  }
}

@include media-breakpoint-down(md) {
  .popover-header,
  .popover-body {
    padding: rem(18px) rem(14px);
  }
}
