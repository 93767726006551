/* management item
   ========================================================================== */

.management-item.list-group-item {
  padding: 0;
}

.management-item-content {
  display: flex;
  padding-top: rem(18px);
  padding-right: rem(8px);
  padding-bottom: rem(18px);
  padding-left: rem(8px);

  &:hover {
    background-color: $list-group-hover-bg;
  }

  .custom-control {
    display: inline-block;
    width: rem(18px);
    height: rem(18px);
    padding: 0;
  }

  .custom-control-label{
    &::after,
    &::before {
      top: 0;
    }
  }
}

.management-item-checkbox {
  display: flex;
  align-items: center;
  padding-right: rem(20px);
  padding-left: rem(20px);
}

.management-item-caret {
  display: flex;
  align-items: center;
  &::before {
    display: inline-block;
    width: 0;
    height: 0;
    margin-right: rem(18px);
    content: "";
    border-color: transparent transparent transparent gray("300");
    border-style: solid;
    border-width: $caret-width 0 $caret-width $caret-width;
    transition: transform .15s ease-out;
  }

  .management-item-group.active &::before {
    transform: rotate(90deg);
  }
}

.management-item-input,
.management-item-symbol {
  padding-top: rem(1px);
  padding-right: rem(8px);
  padding-left: rem(8px);
  text-align: center;
}

.management-item-main {
  flex: 1 1 100%;
  padding-right: rem(6px);
  padding-left: rem(6px);
}

.management-item-action {
  display: flex;
}

.management-item-group {
  cursor: pointer;

  .management-item {
    border-top: 1px solid gray("200");
  }

  .management-item-content {
    min-height: rem(50px);
    padding-top: 0;
    padding-bottom: 0;
  }

  .management-item-main {
    display: flex;
    align-items: center;
  }

  .management-item-symbol {
    display: flex;
    align-items: center;
    padding-right: rem(10px);
    padding-left: 0;
  }

  /* stylelint-disable declaration-no-important */
  .management-item-action {
    align-items: center;
    .btn {
      display: flex !important;
      min-height: rem(30px);
    }
  }
  /* stylelint-enable declaration-no-important */

  .management-item-spacing {
    padding-left: rem(58px);
  }
}

.management-item-grouplist {
  padding-left: 0;
  list-style: none;

  .management-item-group:not(.active) & {
    display: none;
  }
}

@include media-breakpoint-down(md) {
  .management-item-input + .management-item-symbol {
    display: none;
  }

  .management-item-action {
    align-items: center;
  }
}

@include media-breakpoint-up(lg) {
  .management-item-content {
    padding-right: rem(15px);
    padding-left: rem(15px);
  }

  .management-item-input,
  .management-item-symbol {
    padding-right: rem(15px);
    padding-left: rem(15px);
  }

  .management-item-symbol:first-child {
    padding-left: rem(8px);
  }

  .management-item-main {
    padding-right: rem(2px);
    padding-left: rem(2px);
  }


  .management-item-action {
    align-items: flex-start;
    padding-right: rem(10px);
    padding-left: rem(46px);
  }
}
