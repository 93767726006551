/* mastheader
   ========================================================================== */

$mastheader-height:   rem(32px);

.mastheader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: ($zindex-fixed + 1);
  display: flex;
  align-items: center;
  width: 100%;
  height: $mastheader-height;
  font-size: $font-size-sm;
  background-color: gray("600");

  > .container {
    display: flex;
    justify-content: space-between;
    height: 100%;
  }
}

.mastheader-logo {
  padding-right: rem(20px);
}

.mastheader-title {
  margin-bottom: 0;
  font-size: $font-size-sm;
  font-weight: $font-weight-base;
  color: color("white");
  text-transform: none;
}

.mastheader-toolbar {
  padding-left: 0;
  list-style: none;
}

.mastheader-toolbar-item {
  height: 100%;
  padding-left: rem(10px);

  &:not(:last-child) {
    padding-right: rem(10px);
  }

  > button {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0;
    color: color("white");
    cursor: pointer;
    background-color: transparent;
    border: none;
  }
}

.mastheader-toolbar-item-lg {
  > button {
    padding-right: rem(20px);
    padding-left: rem(20px);
    text-transform: uppercase;
    background-color: gray("500");
  }
}
