/* mastfooter
   ========================================================================== */

.mastfooter {
  .text-gray300 {
    @include hover-focus-active { color: color("white"); }
  }
}
.mastfooter-lang {
  li {
    display: inline-block;
    padding-right: rem(2px);
    padding-left: rem(2px);
  }
  a {
    display: inline-block;
    @include hover-focus-active {
      text-decoration: none;
    }
    &:not(:last-child) { padding-right: rem(10px); }
    font-weight: 500;
  }
}

.mastfooter-links {
  padding-left: 0;
  list-style: none;
}

@include media-breakpoint-down(sm) {
  .mastfooter-links {
    border-bottom: 1px solid #4d4f53;
  }
}
