/* range slider
   ========================================================================== */

.range-slider {
  display: flex;
  align-items: center;
  font-size: rem(14px);
  font-weight: $font-weight-medium;
  color: theme-color("primary");

  [class^="icons-"] {
    color: $body-color;
  }
}

.irs {
  width: 100%;
  height: 26px;
}

.irs-with-grid {
  height: 75px;
}

.irs--flat .irs-line {
  top: 8px;
  height: 10px;
  background: gray("100");
  border-radius: 5px;
}

.irs-line-left {
  height: 8px;
}

.irs-line-mid {
  height: 8px;
}

.irs-line-right {
  height: 8px;
}

.irs--flat .irs-bar {
  top: 8px;
  height: 10px;
  background: theme-color("primary");
}
.irs-bar-edge {
  top: 8px;
  width: 14px;
  height: 10px;
  background: theme-color("primary");
  border-right: 0;
  border-radius: 5px 0 0 5px;
}

.irs--flat .irs-handle {
  top: 0;
  width: 26px;
  height: 26px;
  cursor: pointer;
  background: theme-color("primary");
  border: 1px solid theme-color("primary");
  border-radius: 50%;
}

.irs--flat .irs-handle i {
  display: none;
  opacity: 0;
}

.irs--flat .irs-min,
.irs--flat .irs-max,
.irs--flat .irs-from,
.irs--flat .irs-to,
.irs--single {
  font-size: 14px;
  font-weight: $font-weight-medium;
  line-height: 26px;
  color: color("white");
  cursor: pointer;
  background-color: transparent;
  opacity: 0;

  @include hover-focus {
    opacity: 1;
  }

  &::before {
    display: none;
    opacity: 0;
  }
}

.irs--flat .irs-from,
.irs--flat .irs-to,
.irs--single {
  z-index: 10;
}

.range-slider.is-disabled {
  color: gray("300");
  pointer-events: none;

  .irs-bar,
  .irs-bar-edge,
  .irs-handle {
    background-color: gray("300");
  }

  .irs-handle {
    border-color: gray("300");
  }
}
