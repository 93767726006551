/* breadcrumb
   ========================================================================== */

.breadcrumb {
  font-weight: $font-weight-medium;
  border-bottom: 1px solid gray("100");
  border-radius: 0;
}

.breadcrumb-item {
  font-weight: $font-weight-medium;

  // The separator between breadcrumbs (by default, a forward-slash: "/")
  + .breadcrumb-item::before {
    width: rem(8px) + $breadcrumb-item-padding;
    height: rem(10px);
    content: "";
    background-image: $breadcrumb-active-divider;
    background-repeat: no-repeat;
    background-position: center;
    background-size: rem(8px) rem(10px);
  }

  &.active {
    color: $breadcrumb-color;

    & ~ .breadcrumb-item {
      &::before {
        background-image: $breadcrumb-divider;
      }

      a {
        font-weight: $font-weight-normal;
        color: gray("400");
      }
    }

    + .breadcrumb-item::before {
      background-image: $breadcrumb-current-divider;
    }
  }
  &.disabled a {
    cursor: default;
  }
}

.breadcrumb-lg {
  font-size: $h1-font-size;
  line-height: $h1-line-height;

  .breadcrumb-item {
    + .breadcrumb-item::before {
      width: rem(10px) + $breadcrumb-item-padding;
      height: rem(14px);
      background-size: rem(10px) rem(14px);
    }
  }
}

@include media-breakpoint-down(md) {
  .breadcrumb-lg {
    font-size: $h1-font-size-md;
    line-height: $h1-line-height-md;
  }
}
