/* overflow
   ========================================================================== */
// stylelint-disable declaration-no-important

//
// Text
//

// Weight and italics

.overflow-hidden { overflow: hidden !important; }
.overflow-y { overflow-y: auto !important; }
