// stylelint-disable declaration-no-important

.bd-masthead {
  position: relative;
  padding: 3rem ($grid-gutter-width / 2);
  // background-image: linear-gradient(45deg, #fafafa, #f5f5f5);

  background-color: $doc-masthead-bg;

  h1 {
    line-height: 1;
  }

  .btn:not(.btn-link) {
    width: 100%;
    padding: .8rem 2rem;
    font-size: 1.25rem;
    font-weight: 500;
  }

  .carbonad {
    margin-top: 0 !important;
    margin-bottom: -3rem !important;
  }

  @include media-breakpoint-up(sm) {
    padding-top: 5rem;
    padding-bottom: 5rem;

    .carbonad {
      margin-bottom: 0 !important;
    }
  }

  @include media-breakpoint-up(md) {
    h1 {
      font-size: 4rem;
    }

    .carbonad {
      margin-top: 3rem !important;
    }
  }
}

.half-rule {
  width: 6rem;
  margin: 2.5rem 0;
}

.masthead-followup {
  .bd-clipboard { display: none; }

  .highlight {
    padding: .5rem 0;
    background-color: transparent;
  }
}
