/* form toolbar
   ========================================================================== */

.form-toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: rem(38px);
  padding-left: rem(10px);
  line-height: 1;
  border: 1px solid $form-toolbar-border-color;
  border-bottom: 0;
  border-radius: 7px 7px 0 0;

  + .form-control-container > .form-control {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.form-toolbar-item {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: rem(38px);
  height: rem(24px);
  color: $form-toolbar-color;
  text-align: center;
  background-color: transparent;
  border: 0;

  @include hover-focus-active {
    color: theme-color("primary");
  }
}

.form-toolbar-separator {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: rem(40px);
  height: rem(24px);
  margin: 0;
  text-align: center;
  border: 0;

  &::after {
    display: inline-block;
    width: rem(1px);
    height: rem(16px);
    content: "";
    background-color: gray("200");
  }
}
