//
// Footer
//

.bd-footer {
  font-size: 85%;
  color: $doc-footer-color;
  text-align: center;
  background-color: $doc-footer-bg;

  a {
    font-weight: 500;
    color: $doc-footer-color;

    &:hover,
    &:focus {
      color: $link-color;
    }
  }

  p {
    margin-bottom: 0;
  }

  @include media-breakpoint-up(sm) {
    text-align: left;
  }
}

.bd-footer-links {
  padding-left: 0;
  margin-bottom: 1rem;

  li {
    display: inline-block;

    + li {
      margin-left: 1rem;
    }
  }
}
