/* menubar
   ========================================================================== */

$menubar-height:    rem(60px);

// md/768 +
$menubar-height-md: rem(76px);

.mastnav {
  position: fixed;
  top: $mastheader-height;
  left: 0;
  z-index: $zindex-fixed;
  width: 100%;
  background-color: $menubar-bg;
}

.menubar {
  width: 100%;
  height: $menubar-height;
  color: $menubar-color;
  border-bottom: 1px solid $border-color;

  > .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  }

  a:not(:hover) {
    color: gray("600");
  }
}

//
// nav
//

.nav-item {
  display: flex;
  align-items: center;
  padding: 0;
  font-weight: $font-weight-medium;
  color: $menubar-color;
  cursor: pointer;
  background-color: transparent;
  border: none;

  // &:focus { outline: 0; }
}

//
// menu
//

.menu {
  position: absolute;
  z-index: $zindex-sticky;
  color: color("white");
  background-color: theme-color("primary");
}

//
// subnav
//

.subnav-item {
  display: flex;
  align-items: center;
  padding: 0;
  font-weight: $font-weight-medium;
  color: $menu-secondary-color;
  white-space: nowrap;
  background-color: transparent;
  border: none;

  + .subnav-item {
    padding-top: rem(22px);
  }

  // &:focus { outline: 0; }
}

.subnav-btn {
  display: flex;
  align-items: center;
  color: inherit;
  background-color: transparent;
  border: none;

  &.active {
    &::after {
      position: absolute;
      left: 100%;
      z-index: 2;
      display: block;
      width: rem(18px);
      height: rem(18px);
      margin-left: rem(-9px);
      content: "";
      background-color: theme-color("primary");
      transform: rotate(45deg);
    }
  }
}


//
// Mobile
//

@include media-breakpoint-down(sm) {
  .mastnav {
    z-index: $zindex-fixed;
  }

  .menubar-menu {
    position: absolute;
    top: $menubar-height;
    left: 0;
    width: 100%;
    height: calc(100vh - #{$menubar-height});
    padding-top: rem(15px);
    padding-right: rem(20px);
    padding-left: rem(20px);
    background-color: color("white");

    &:not(.active) {
      display: none;
    }
  }

  //
  // nav
  //

  .nav {
    display: flex;
    flex-direction: column;
  }

  .nav-item {
    width: 100%;

    + .nav-item {
      margin-top: rem(12px);
    }
  }

  //
  // menu
  //

  .menu {
    position: fixed;
    //top: 0; Seems to create some problems ?
    left: 0;
    width: 100%;
    > .container {
      padding-right: 0;
      padding-left: 0;
    }
  }

  .panel-inner {
    display: flex;
    flex-direction: column;
  }

  .panel-head,
  .panel-content {
    @include make-container();
  }

  .panel-head {
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: $menubar-height;
    color: gray("600");
    background-color: color("white");
    border-bottom: 1px solid $border-color;

    .h3 {
      display: flex;
      align-items: center;
    }
  }

  .panel-content {
    flex: 1 1 auto;
    padding-top: rem(30px);
    overflow-y: auto;
  }

  .panel-close {
    position: relative;
    color: gray("600");

    &::before {
      position: absolute;
      top: calc(50% - #{rem(9px)});
      left: 0;
      display: block;
      width: rem(1px);
      height: rem(18px);
      content: "";
      background-color: #e6e6e6;
    }
  }

  .panel-lead {
    display: none;
  }

  .primary-panel,
  .secondary-panel {
    min-height: 100vh;

    &:not(.active) {
      display: none;
    }
  }

  .primary-panel {
    z-index: 2;
  }

  .secondary-panel {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    width: 100%;
    background-color: theme-color("primary-dark");
  }
}


//
// Desktop
//

@include media-breakpoint-up(md) {
  .menubar {
    height: $menubar-height-md;
  }

  .menubar-menu,
  .nav,
  .nav-item {
    height: 100%;
  }

  .menubar-menu {
    flex: 1 1 100%;
    padding: 0 0 0 rem(50px);
  }

  //
  // nav
  //

  .nav-item {
    height: 100%;
    + .nav-item {
      padding-left: rem(40px);
    }

    [class^="icons-arrow-"] {
      transition: transform .15s ease-out;
    }

    &.active {
      [class^="icons-arrow-"] {
        transform: rotate(180deg);
      }
    }
  }

  //
  // menu
  //

  .menu {
    top: calc(100% - #{rem(1px)}); // minus border-bottom
    left: 0;
    width: 100%;
    overflow: hidden;
    font-weight: $font-weight-medium;
    color: $menu-color;

    h1,
    .h1,
    h2,
    .h2,
    h3,
    .h3,
    a,
    button {
      color: $menu-color;
    }

    > .container {
      display: flex;
    }
  }

  .menu-item {
    padding-top: rem(50px);
    padding-bottom: rem(50px);
    a,
    button {
      cursor: pointer;
    }
    &.secondary-panel .subnav-item {
      color: $menu-secondary-color;
    }
  }

  .panel-head {
    display: none;
  }

  .primary-panel,
  .secondary-panel {
    &:not(.active) {
      display: none;
    }
  }

  .primary-panel {
    flex: 0 0 61.7529880478%;

    .panel-content {
      display: flex;
      justify-content: space-between;
    }

    .panel-lead {
      max-width: rem(260px);
    }

    .subnav {
      position: relative;
      display: flex;
      flex: 0 0 auto;
      flex-direction: column;
      align-items: flex-end;
      min-width: 0;
      padding-right: 12.904%;
      padding-left: 8.067%;
    }
  }

  .secondary-panel {
    position: relative;
    flex: 0 0 38.2470119522%;
    background-color: theme-color("primary-dark");

    &:not(.active) {
      display: none;
    }

    &::after {
      position: absolute;
      top: 0;
      left: 100%;
      display: block;
      width: 100vw;
      height: 100%;
      content: "";
      background-color: theme-color("primary-dark");
    }

    .panel-content {
      padding-left: 20.836%;
    }
  }
}
