/* custom forms
   ========================================================================== */

.custom-control-label {
  font-size: $font-size-sm;
}

.select-control .custom-control-label {
  font-size: $font-size-base;
}

// Options
//
// Radios options

.options-control-lg {
  .options-btn {
    padding: rem(10px) rem(20px);
  }
}

.options-control-actionbar {
  background-color: color("white");

  .options-btn {
    color: theme-color("primary");
    border-radius: $btn-border-radius;
  }

  // stylelint-disable selector-no-qualifying-type
  input:checked + .options-btn,
  input[disabled]:checked + .options-btn {
    color: color("white");
    background-color: theme-color("primary");
  }
  // stylelint-enable selector-no-qualifying-type
}

@include media-breakpoint-down(sm) {
  .options-control-actionbar {
    &.options-control-lg {
      .options-btn {
        padding-top: rem(14px);
        padding-bottom: rem(14px);
      }
    }
  }
}
