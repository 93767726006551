/* swiper
   ========================================================================== */

.swiper {
  position: relative;
}

.swiper-button-prev,
.swiper-button-next {
  display: flex;
  align-items: center;
  justify-content: center;
  width: rem(40px);
  height: rem(40px);
  margin-top: rem(-20px);
  color: $swiper-btn-color;
  background-color: $swiper-btn-bg;
  background-image: none;
  border: none;
  border-radius: 50%;

  &:not(.swiper-button-disabled):hover {
    color: $swiper-btn-disabled-color;
    background-color: $swiper-btn-disabled-bg;
  }

  /* stylelint-disable declaration-no-important */
  &.swiper-button-disabled {
    pointer-events: none !important;
  }
  /* stylelint-enable declaration-no-important */
}

.swiper-pagination {
  position: static;
  display: flex;
  justify-content: center;
  width: 100%;
}

.swiper-pagination-bullet {
  width: rem(10px);
  height: rem(10px);
  margin-right: rem(5px);
  margin-left: rem(5px);
  background-color: transparent;
  border: 2px solid gray("400");
  opacity: 1;
}

.swiper-pagination-bullet-active {
  background-color: theme-color("primary");
  border-color: theme-color("primary");
}
