/* sami
   ========================================================================== */

.sami {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: rem(50px);
  height: rem(50px);
  color: $sami-color;
  text-transform: uppercase;
  background-color: $sami-bg;
  border-radius: $border-radius;
}

@each $color, $value in $sami-colors {
  .sami-#{$color} {
    background-color: $value;
  }

  .sami-checkbox-#{$color} {
    cursor: pointer;

    input:checked + & {
      background-color: $value;
    }
  }
}
