/* backgrounds
   ========================================================================== */
// stylelint-disable declaration-no-important

// Contextual colors

@each $color, $value in $colors {
  .text-#{$color} {
    color: $value;
  }
}

@each $color, $value in $colors {
  .bg-#{$color} {
    background-color: $value;
  }
}

@each $color, $value in $grays {
  .bg-gray#{$color} {
    background-color: $value;
  }
}
