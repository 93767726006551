/* navtabs
   ========================================================================== */

.navtabs {
  display: flex;
  padding-left: 0;
  overflow: hidden;
  white-space: nowrap;
  list-style: none;

  &::before,
  &::after {
    position: absolute;
    top: 0;
    z-index: 2;
    width: 4rem;
    height: 100%;
    pointer-events: none;
    content: "";
  }

  &.scroll-left-max {
    &::before {
      left: 0;
      background: linear-gradient(to right, #fff 2%, rgba(255, 255, 255, 0) 98%);
    }
  }

  &.scroll-left-min {
    &::after {
      right: 0;
      background: linear-gradient(to left, #fff 2%, rgba(255, 255, 255, 0) 98%);
    }
  }

  &.nav {
    flex-wrap: initial;
  }
}

.navtabs-item {
  padding-right: rem(20px);

  a {
    display: block;
    color: $nav-tabs-color;

    @include hover-focus-active {
      position: relative;
      color: theme-color("primary");
    }

    // stylelint-disable selector-no-qualifying-type
    &.active {
      font-weight: $font-weight-medium;
      color: theme-color("primary");
    }
    // stylelint-enable selector-no-qualifying-type
  }
}

@include media-breakpoint-up(md) {
  .navtabs-item {
    padding-right: rem(20px);

    a {
      padding-top: rem(4px);
      padding-bottom: rem(16px);

      // stylelint-disable selector-no-qualifying-type
      &.active {
        &::after {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: rem(5px);
          content: "";
          background-color: theme-color("primary");
          border-radius: 3px;
        }
      }
      // stylelint-enable selector-no-qualifying-type
    }
  }
}
