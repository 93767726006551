/* type
   ========================================================================== */

//
// Headings
//

h1,
.h1,
h2,
.h2,
.display-1 {
  text-transform: uppercase;
}

h1,
.h1,
.display-1 {
  font-weight: $h1-font-weight;
  line-height: $h1-line-height;
}

h2,
.h2 {
  font-weight: $h2-font-weight;
}

h3,
.h3 {
  font-weight: $h3-font-weight;
}

.display-2 {
  font-size: $h2-font-size;
  text-transform: none;
}

.display-3 {
  font-size: $h3-font-size;
  text-transform: none;
}

.lead {
  color: $lead-color;
}

// Blockquotes
.blockquote-decoration {
  position: relative;
  padding-right: rem(30px);
  padding-left: rem(30px);
  font-weight: $font-weight-medium;

  &::before,
  &::after {
    position: absolute;
    display: block;
    width: rem(14px);
    height: rem(18px);
    content: "";
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }

  &::before {
    top: 0;
    left: 0;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 541.3 500.03'%3e%3cdefs%3e%3cstyle%3e.a%7bfill:%230088ce;%7d%3c/style%3e%3c/defs%3e%3ctitle%3earrow-double%3c/title%3e%3cpath class='a' d='M0,250.87,235.7,11.37c15.2-15.2,42.6-15.2,61.6,0h0c15.2,15.2,15.2,46.4,0,61.6L123.2,250.87,298,424.17c15.2,19,15.2,46.4,0,61.6l-7.6,7.6c-19,11.4-42.6,7.6-57.8-7.6Z' transform='translate(0 0.03)'/%3e%3cpath class='a' d='M231.9,250.87,467.6,11.37c15.2-15.2,42.6-15.2,61.6,0h0c15.1,15.2,15.1,46.4-.1,61.6L355,250.87l174.9,173.3c15.2,19,15.2,46.4,0,61.6l-7.6,7.6c-19,11.4-42.6,7.6-57.8-7.6Z' transform='translate(0 0.03)'/%3e%3c/svg%3e");
  }
  &::after {
    right: 0;
    bottom: 0;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 541.3 500.03'%3e%3cdefs%3e%3cstyle%3e.a%7bfill:%230088ce;%7d%3c/style%3e%3c/defs%3e%3ctitle%3earrow-double%3c/title%3e%3cpath class='a' d='M541.3,249.1,305.6,488.6c-15.2,15.2-42.6,15.2-61.6,0h0c-15.2-15.2-15.2-46.4,0-61.6L418.1,249.1,243.3,75.8c-15.2-19-15.2-46.4,0-61.6l7.6-7.6c19-11.4,42.6-7.6,57.8,7.6Z' transform='translate(0 0.03)'/%3e%3cpath class='a' d='M309.4,249.1,73.7,488.6c-15.2,15.2-42.6,15.2-61.6,0h0C-3,473.4-3,442.2,12.2,427L186.3,249.1,11.4,75.8c-15.2-19-15.2-46.4,0-61.6L19,6.6C38-4.8,61.6-1,76.8,14.2Z' transform='translate(0 0.03)'/%3e%3c/svg%3e");
  }
}

.blockquote-footer {
  padding-right: rem(30px);
  padding-left: rem(30px);
  font-size: $font-size-base;
  font-weight: $font-weight-medium;
  text-align: right;

  &::before {
    content: ""; // remove em dash, nbsp
  }
}

@include media-breakpoint-up(md) {
  h1,
  .h1 {
    font-size: $h1-font-size-md;
    line-height: $h1-line-height-md;
  }

  h2,
  .h2 {
    font-size: $h2-font-size-md;
    line-height: $headings-line-height-md;
  }

  h3,
  .h3 {
    font-size: $h3-font-size-md;
    line-height: $headings-line-height-md;
  }

  .display-1 {
    font-size: $display1-size-md;
    line-height: $display1-line-height-md;
  }

  .display-2 {
    font-size: $display2-size-md;
    line-height: $display-line-height-md;
  }

  .display-3 {
    font-size: $display3-size-md;
    line-height: $display-line-height-md;
  }
}
