/* evidences
   ========================================================================== */

.proof {
  border-radius: $border-radius;

  &.is-gray {
    background-color: $proof-gray-bg;
  }

  .display-3 {
    color: currentColor;
  }
}

.proof-icon {
  text-align: center;
}

@include media-breakpoint-down(sm) {
  .proof {
    padding: rem(25px) rem(20px);
  }
}

@include media-breakpoint-up(md) {
  .proof {
    display: flex;
    align-items: center;
    min-height: rem(200px);
    padding-top: rem(40px);
    padding-bottom: rem(40px);

    &.has-strip {
      background-image: linear-gradient($proof-strip-color 50%, transparent 50%);
      background-repeat: repeat-y;
      background-position: top right;
      background-size: rem(44px) rem(38px);
    }

    &.has-strip:not(.is-gray) {
      background-image: linear-gradient(rgba(255, 255, 255, .2) 50%, transparent 50%);
    }
  }

  .proof-icon {
    display: flex;
    flex: 0 0 45%;
    align-items: center;
    justify-content: center;
  }

  .proof-content {
    display: flex;
    flex: 0 0 55%;
    flex-direction: column;
    justify-content: center;
    padding-right: 10%;
  }
}
