/* anchor
   ========================================================================== */

.anchor {
  position: relative;
  background-image: linear-gradient(0deg, $anchor-gradient-color 50%, transparent 50%);
  background-repeat: repeat-y;
  background-position: top left;
  background-size: rem(13px) rem(4px);
}

.anchor-item {
  position: relative;
  display: flex;
  color: $anchor-item-color;

  &:first-child::after,
  &:last-child::after {
    position: absolute;
    left: 0;
    z-index: 1;
    display: block;
    width: rem(13px);
    height: rem(10px);
    content: "";
    background-color: $anchor-item-bg;
  }
  &:first-child::after { top: 0; }
  &:last-child::after { bottom: 0; }

  &:not(:last-child) {
    padding-bottom: rem(17px);
  }

  &:hover {
    color: theme-color("primary-dark");
  }

  &::before {
    position: relative;
    z-index: 2;
    display: block;
    width: rem(13px);
    height: rem(13px);
    margin-top: rem(3px);
    margin-right: rem(18px);
    content: "";
    background-color: currentColor;
    border-radius: 50%;
  }

  &.current {
    font-weight: $font-weight-medium;
    color: theme-color("primary");

    &::after {
      position: absolute;
      top: rem(10px);
      left: 0;
      z-index: 1;
      display: block;
      width: rem(13px);
      height: calc(100% - 10px);
      content: "";
      background-color: $anchor-item-bg;
    }

    &:first-child::after {
      top: 0;
      height: 100%;
    }

    ~ .anchor-item {
      &::after {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        display: block;
        width: rem(13px);
        height: 100%;
        content: "";
        background-color: $anchor-item-bg;
      }
    }
  }
}
