/* lists
   ========================================================================== */

.meta-list {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding-left: 0;
  list-style: none;
}

.meta-list-item {
  @each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-only($breakpoint) {
      $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

      &.separator#{$infix}-none {
        padding-left: 0;

        &::before {
          display: none;
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .meta-list {
    display: flex;
    flex-direction: row;
  }

  .meta-list-item {
    padding-right: rem(20px);

    &.separator {
      position: relative;
      padding-left: rem(22px);

      &::before {
        position: absolute;
        top: rem(4px);
        left: 0;
        display: block;
        width: rem(2px);
        height: rem(12px);
        content: "";
        background-color: #8a8b8d;
      }
    }
  }
}
