/* carousel
   ========================================================================== */

.bg-carousel {
  background-color: $carousel-bg;
}

.carousel-item-content {
  display: flex;
  flex-direction: column;
  padding: rem(30px) rem(25px) rem(60px);
}

.carousel-item-headtext {
  order: -1;
}

.carousel-controls {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding-bottom: rem(30px);
  pointer-events: none;
}

@include media-breakpoint-up(md) {
  .carousel-controls {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-bottom: rem(40px);

    > .row {
      height: 100%;
    }

    .swiper-button-prev,
    .swiper-button-next {
      position: relative;
      top: auto;
      right: auto;
      bottom: auto;
      left: auto;
      pointer-events: initial;
    }

    .swiper-button-next {
      margin-left: rem(10px);
    }

    .swiper-pagination-bullet {
      width: rem(15px);
      height: rem(15px);
    }
  }

  .carousel-item-content {
    padding: rem(60px) rem(40px) rem(120px) 0;
  }
}
